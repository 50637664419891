import { createRouter, createWebHistory } from "vue-router";
import {
  Home,
  UserManagement,
  ClientRegistration,
  MultiAccount,
  Report,
  PartnerManagement,
  Vendor,
} from "@icons/index";
import TheHeader from "@/components/TheHeader.vue";
import Layout from "@/layouts/index.vue";
import TheHeaderDashboard from "@/components/TheHeaderDashboard.vue";
import { useCookies } from "@vueuse/integrations/useCookies";
import { useSession } from "@/composables";
import { useStateStore } from "@/stores/state";
import { storeToRefs } from "pinia";
const session = useSession();

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/HomeView.vue"),
      redirect: "/dashboard",
      children: [
        {
          path: "/dashboard",
          name: "Dashboard",
          components: {
            default: () => import("@/views/home/dashboard/DashboardView.vue"),
            header: TheHeaderDashboard,
          },
          meta: {
            icon: Home,
            label: "Dashboard",
            code: "dashboard",
          },
        },
        {
          path: "/task",
          name: "Task",
          components: {
            default: () => import("@/views/home/task/TaskView.vue"),
            header: TheHeader,
          },
          meta: {
            icon: Report,
            label: session.value.role?.role_type?.id == 1 ? "Create Task" : "Daftar Task",
            code: "task",
            desc: "Kamu bisa mengatur timeline dan publish task",
          },
        },
        {
          path: "/okr",
          name: "OKR Company & Dir Div",
          components: {
            default: Layout,
            header: TheHeader,
          },
          meta: {
            icon: ClientRegistration,
            label: "OKR Company & Dir Div",
            code: "okr",
            desc: "Kamu bisa mengatur OKR Company, Direktorat dan Divisi",
          },
          redirect: "/okr/company",
          children: [
            {
              path: "company",
              name: "Company Root",
              component: Layout,
              meta: {
                label: "Company",
                roles: [1, 2],
              },
              beforeEnter: (to, from, next) => {
                if (session.value.role?.role_type?.id == 3) {
                  next({ name: "DirDiv" });
                } else {
                  next();
                }
              },
              redirect: "/okr/company",
              children: [
                {
                  path: "",
                  name: "Company",
                  component: () => import("@/views/home/okr/company/CompanyView.vue"),
                  meta: {
                    hidden: {
                      breadcrumbs: true,
                    },
                  },
                },
                {
                  path: "result",
                  name: "Company Result",
                  component: () => import("@/views/home/okr/company/CompanyResult.vue"),
                  meta: {
                    label: "Result",
                    hidden: {
                      submenu: true,
                    },
                  },
                },
              ],
            },
            {
              path: "dir-div",
              name: "DirDiv Root",
              component: Layout,
              meta: {
                label: "DirDiv",
                roles: [1, 2, 3],
              },
              redirect: "/okr/dir-div",
              children: [
                {
                  path: "",
                  name: "DirDiv",
                  component: () => import("@/views/home/okr/dir-div/DirDivView.vue"),
                  meta: {
                    hidden: {
                      breadcrumbs: true,
                    },
                  },
                },
                {
                  path: "result",
                  name: "DirDiv Result",
                  component: () => import("@/views/home/okr/dir-div/DirDivResult.vue"),
                  meta: {
                    label: "Result",
                    hidden: {
                      submenu: true,
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          path: "/set-goals",
          name: "Set Goals",
          components: {
            default: Layout,
            header: TheHeader,
          },
          redirect: "/set-goals",
          meta: {
            icon: PartnerManagement,
            label: "Set Goals",
            code: "goals",
            desc: "Silahkan Input Setting Goals untuk Quartal selanjutnya",
          },
          children: [
            {
              path: "",
              name: "Set Goals Root",
              component: Layout,
              meta: {
                hidden: {
                  breadcrumbs: true,
                },
                label: "Set Goals",
              },
              children: [
                {
                  path: "",
                  name: "List Set Goals",
                  component: () => import("@/views/home/set-goals/SetGoalsView.vue"),
                  meta: {
                    hidden: {
                      breadcrumbs: true,
                    },
                  },
                  beforeEnter: (from, to, next) => {
                    if (session.value.role?.id != 1) {
                      next({
                        name: "Set Goals Details",
                      });
                    } else {
                      next();
                    }
                  },
                },
                {
                  path: "details",
                  name: "Set Goals Details",
                  component: () => {
                    if (session.value.role?.role_type?.id != 1) {
                      return import("@/views/home/set-goals/SetGoalsDetails.vue");
                    } else {
                      return import("@/views/home/set-goals/SetGoalsDetailsAdmin.vue");
                    }
                  },
                  meta: {
                    label: `Set Goals - ${session.value.role?.name}`,
                  },
                },
                {
                  path: "details/turunan",
                  name: "Set Goals Desc",
                  component: () => import("@/views/home/set-goals/SetGoalsDesc.vue"),
                  meta: {
                    label: `Set Goals - ${session.value.role?.name} Turunan`,
                  },
                },
                {
                  path: "details/atasan",
                  name: "Set Goals Asc",
                  component: () => import("@/views/home/set-goals/SetGoalsAsc.vue"),
                  meta: {
                    label: `Set Goals - ${session.value.role?.name} Atassan`,
                  },
                },
                {
                  path: "details/edit",
                  name: "Set Goals Edit",
                  component: () => import("@/views/home/set-goals/SetGoalsEdit.vue"),
                  meta: {
                    label: "Edit Set Goals",
                  },
                  beforeEnter: (to, from, next) => {
                    const useState = useStateStore();
                    const { state } = storeToRefs(useState);
                    if (state.value.employee) {
                      next();
                    } else {
                      next({ name: "Set Goals Desc", query: { quarter: 1 } });
                    }
                  },
                },
                {
                  path: "compare",
                  name: "Set Goals Compare",
                  component: () => import("@/views/home/set-goals/SetGoalsCompare.vue"),
                  meta: {
                    label: "2 Tab Compare",
                  },
                  beforeEnter: (from, to, next) => {
                    if (session.value.role?.role_type_id != 3) {
                      next();
                    } else {
                      next(false);
                    }
                  },
                },
              ],
            },
          ],
        },
        {
          path: "/pdr",
          name: "PDR",
          components: {
            default: Layout,
            header: TheHeader,
          },
          redirect: "/pdr",
          meta: {
            icon: MultiAccount,
            label: "PDR",
            code: "pdr",
            desc: "Silahkan Input hasil pencapaikan kerja kamu",
          },
          children: [
            {
              path: "",
              name: "PDR Root",
              component: Layout,
              meta: {
                hidden: {
                  breadcrumbs: true,
                },
                label: "PDR",
              },
              children: [
                {
                  path: "",
                  name: "PDR View",
                  component: () => import("@/views/home/pdr/PDRView.vue"),
                  meta: {
                    hidden: {
                      breadcrumbs: true,
                    },
                  },
                  beforeEnter: (to, from, next) => {
                    if (session.value.is_superior || session.value.role.role_type.id == 1)
                      return next();
                    else return next({ name: "PDR Saya" });
                  },
                },
                {
                  path: "details",
                  name: "PDR Details Root",
                  component: Layout,
                  meta: {
                    hidden: {
                      breadcrumbs: true,
                    },
                  },
                  redirect: "/pdr/details",
                  children: [
                    {
                      path: "",
                      name: "PDR Admin",
                      component: () => import("@/views/home/pdr/PDRAdmin.vue"),
                      meta: {
                        label: "PDR List",
                      },
                    },
                    {
                      path: "employee/:id",
                      name: "PDR Admin Details",
                      component: () => import("@/views/home/pdr/PDRAdminDetails.vue"),
                      meta: {
                        label: "PDR Detail",
                      },
                      props: true,
                    },

                    {
                      path: "saya",
                      name: "PDR Saya",
                      component: () => import("@/views/home/pdr/PDRSaya.vue"),
                      meta: {
                        label: "PDR Saya",
                      },
                    },
                    {
                      path: "turunan",
                      name: "PDR Desc Details",
                      component: () => import("@/views/home/pdr/PDRDescDetails.vue"),
                      meta: {
                        label: "PDR Turunan",
                      },
                      beforeEnter: async (to, from, next) => {
                        if (session.value.is_superior) return next();
                        else return next({ name: "PDR" });
                      },
                    },
                    {
                      path: "turunan/edit",
                      name: "PDR Edit",
                      component: () => import("@/views/home/pdr/PDREdit.vue"),
                      meta: {
                        label: "Edit PDR",
                      },
                      beforeEnter: (to, from, next) => {
                        const useState = useStateStore();
                        const { state } = storeToRefs(useState);
                        if (state.value.employee) {
                          next();
                        } else {
                          next({
                            name: "PDR Desc Details",
                          });
                        }
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "/release-publish-pdr",
          name: "Release & Publish PDR",
          components: {
            default: () => import("@/views/home/release/ReleaseView.vue"),
            header: TheHeader,
          },
          meta: {
            icon: Report,
            label: "Release & Publish PDR",
            code: "release-publish-pdr",
            desc: "PDR yang sudah dikalibrasi oleh Komite Performance sebagai acuan penilaian di Quartal berjalan",
          },
        },
        {
          path: "/reward",
          name: "Reward",
          components: {
            default: Layout,
            header: TheHeader,
          },
          meta: {
            icon: ClientRegistration,
            label: "Reward",
            code: "reward",
            desc: "Merupakan List Karyawan yang mendapatkan Apresiasi dan Rekomendasi",
          },
          redirect: "/reward/annual-bonus",
          children: [
            {
              path: "annual-bonus",
              name: "Annual Bonus Root",
              component: Layout,
              meta: {
                label: "Annual Bonus",
              },
              redirect: "/reward/annual-bonus",
              children: [
                {
                  path: "",
                  name: "Annual Bonus",
                  component: () => import("@/views/home/reward/RewardBonus.vue"),
                  meta: {
                    hidden: {
                      breadcrumbs: true,
                    },
                  },
                },
              ],
            },
            {
              path: "promosi",
              name: "Promosi Root",
              component: Layout,
              meta: {
                label: "Promosi & Salary Adjustment",
              },
              redirect: "/reward/promosi",
              children: [
                {
                  path: "",
                  name: "Promosi",
                  component: () => import("@/views/home/reward/RewardPromosi.vue"),
                  meta: {
                    hidden: {
                      breadcrumbs: true,
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          path: "/monitoring",
          name: "Monitoring",
          components: {
            default: Layout,
            header: TheHeader,
          },
          meta: {
            icon: UserManagement,
            label: "Monitoring",
            code: "monitoring",
            desc: "Menampilkan Record Activity Penilaian Performance",
          },
          redirect: "/monitoring",
          children: [
            {
              path: "",
              name: "Monitoring Root",
              component: () => import("@/views/home/monitoring/MonitoringView.vue"),
              meta: {
                hidden: {
                  breadcrumbs: true,
                },
              },
            },
            {
              path: "set-goals",
              name: "Monitoring Set Goals & PDR",
              component: () => import("@/views/home/monitoring/MonitoringSetGoals.vue"),
              meta: {
                label: "Set Goals & PDR",
                hidden: {
                  submenu: true,
                },
              },
            },
            {
              path: "grouping-grade",
              name: "Monitoring Grouping Grade",
              component: () => import("@/views/home/monitoring/MonitoringGrade.vue"),
              meta: {
                label: "Grouping Grade",
                hidden: {
                  submenu: true,
                },
              },
            },
          ],
        },
        {
          path: "/employee-management",
          name: "Employee Management",
          meta: {
            label: "Employee Management",
            icon: Vendor,
            collapse: true,
            hidden: {
              submenu: true,
              breadcrumbs: true,
            },
            code: "employee-management",
            desc: "Kamu bisa mengatur data employee",
          },
          redirect: "/employee-management/data-employee",
          children: [
            {
              path: "data-employee",
              name: "Data Employee",
              components: {
                default: Layout,
                header: TheHeader,
              },
              meta: {
                label: "Data Employee",
                hidden: {
                  submenu: true,
                },
              },
              redirect: "/employee-management/data-employee",
              children: [
                {
                  path: "",
                  name: "Data View",
                  component: () => import("@/views/home/employee/data/DataView.vue"),
                  meta: {
                    hidden: {
                      breadcrumbs: true,
                    },
                  },
                },
                {
                  path: "add",
                  name: "Data Add",
                  component: () => import("@/views/home/employee/data/DataAdd.vue"),
                  meta: {
                    label: "Add Employee",
                  },
                },
                {
                  path: "add/bulk",
                  name: "Data Bulk",
                  component: () => import("@/views/home/employee/data/DataBulk.vue"),
                  meta: {
                    label: "Bulk Add Employee",
                  },
                },
                {
                  path: "edit/:id",
                  name: "Data Edit",
                  component: () => import("@/views/home/employee/data/DataEdit.vue"),
                  meta: {
                    label: "Edit Employee",
                  },
                  props: true,
                },
                {
                  path: "details/:id",
                  name: "Data Details",
                  component: () => import("@/views/home/employee/data/DataDetails.vue"),
                  meta: {
                    label: "Details Employee",
                  },
                  props: true,
                },
              ],
            },
            {
              path: "transfer-employee",
              name: "Transfer Employee",
              meta: {
                label: "Transfer Employee",
                hidden: {
                  submenu: true,
                },
              },
              components: {
                header: TheHeader,
                default: Layout,
              },
              redirect: "/employee-management/transfer-employee",
              children: [
                {
                  path: "",
                  name: "Transfer View",
                  component: () => import("@/views/home/employee/transfer/TransferView.vue"),
                  meta: {
                    hidden: {
                      breadcrumbs: true,
                    },
                  },
                },
                {
                  path: "details/:id",
                  name: "Transfer Details",
                  component: () => import("@/views/home/employee/transfer/TransferDetails.vue"),
                  props: true,
                  meta: {
                    label: "Detail Employee Transfer",
                  },
                },
                {
                  path: "edit/:id",
                  name: "Transfer Edit",
                  component: () => import("@/views/home/employee/transfer/TransferEdit.vue"),
                  props: true,
                  meta: {
                    label: "Edit Employee Transfer",
                  },
                },
                {
                  path: "add",
                  name: "Transfer Add",
                  component: () => import("@/views/home/employee/transfer/TransferAdd.vue"),
                  meta: {
                    label: "Create Transfer",
                  },
                },
                {
                  path: "add/bulk",
                  name: "Transfer Bulk",
                  component: () => import("@/views/home/employee/transfer/TransferBulk.vue"),
                  meta: {
                    label: "Bulk Transfer Employee",
                  },
                },
              ],
            },
            {
              path: "dir-div",
              name: "DirDiv CRUD",
              components: {
                default: () => import("@/views/home/dirdiv/DirdivView.vue"),
                header: TheHeader,
              },
              meta: {
                label: "Direktorat/Divisi",
                hidden: {
                  submenu: true,
                },
              },
            },
            {
              path: "job-title",
              name: "Job Title CRUD",
              components: {
                default: () => import("@/views/home/employee/job-title/JobTitleView.vue"),
                header: TheHeader,
              },
              meta: {
                label: "Job Title",
                hidden: {
                  submenu: true,
                },
              },
            },
          ],
        },
        {
          path: "/role-management",
          name: "Role Management",
          meta: {
            label: "Role Management",
            icon: MultiAccount,
            code: "role-management",
            hidden: {
              submenu: true,
            },
            desc: "Kamu bisa mengatur role employee",
          },
          components: {
            default: Layout,
            header: TheHeader,
          },
          redirect: "/role-management",
          children: [
            {
              path: "",
              name: "Role View",
              component: () => import("@/views/home/role/RoleView.vue"),
              meta: {
                label: "Role Management",
                hidden: {
                  submenu: true,
                  breadcrumbs: true,
                },
              },
            },
            {
              path: "add",
              name: "Role Add",
              component: () => import("@/views/home/role/RoleAdd.vue"),
              meta: {
                label: "Create Role",
                hidden: {
                  submenu: true,
                },
              },
            },
            {
              path: "edit/:id",
              name: "Role Edit",
              component: () => import("@/views/home/role/RoleEdit.vue"),
              meta: {
                label: "Edit Role",
                hidden: {
                  submenu: true,
                },
              },
              props: true,
            },
          ],
        },
        {
          path: "/notifikasi",
          name: "Notification",
          components: {
            default: () => import("@/views/home/notification/NotificationView.vue"),
            header: TheHeader,
          },
          meta: {
            label: "Notifikasi",
            hiddenMenu: true,
          },
        },
      ],
      beforeEnter: (to, from, next) => {
        const cookies = useCookies();
        const session = cookies.get("session-lipmance");
        if (session) {
          next();
        } else {
          next({ name: "Login" });
        }
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/LoginView.vue"),
      beforeEnter: (to, from, next) => {
        const cookies = useCookies();
        const session = cookies.get("session-lipmance");

        if (session) {
          next({ name: "Dashboard" });
        } else {
          next();
        }
      },
    },
    {
      path: "/forgot-password",
      name: "Forgot Password",
      component: () => import("@/views/ForgotPasswordView.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicMenu = ["Login", "Forgot Password", "Notification", "Home", "Dashboard"];
  if (publicMenu.includes(to.name)) return next();
  if (session.value?.menu_access?.includes(to.meta.code)) {
    next();
  } else {
    next(false);
  }
});

export default router;
