import useDayJS from "./dayjs";

const useUtility = () => {
  const dayjs = useDayJS();
  const getAsset = (filename) => {
    return new URL(`../assets/${filename}`, import.meta.url).href;
  };

  const randomize = () => Math.round(Math.random() * 10) % 2 == 0;

  const getAvatar = (name) =>
    `https://ui-avatars.com/api/?name=${name}&background=4FB0EC&color=FFFFFF&rounded=true`;

  const getQuarter = (q, year = dayjs().format("YYYY")) => {
    if (q == 1) return `01 Januari ${year} - 31 Maret ${year}`;
    if (q == 2) return `01 April ${year} - 30 Juni ${year}`;
    if (q == 3) return `01 Juli ${year} - 30 September ${year}`;
    if (q == 4) return `01 Oktober ${year} - 31 Desember ${year}`;
  };

  const downloadCsv = (data, title) => {
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${dayjs().format("YYYYMMDD")} - ${title}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const toMinutes = (time = "") => {
    if (typeof time != "string" || time == "") {
      if (time == "") return null;
      else return time;
    }
    if (time.includes(",")) {
      return parseSeparator(time, true);
    }
    if (!time.includes(":")) return parseFloat(time);
    const a = time.split(":");
    return +a[0] * 60 + +a[1];
  };

  const toTime = (minutesVal = 0, toText = false) => {
    const hours = Math.floor(minutesVal / 60);
    const minutes = minutesVal % 60;

    const hoursString = `${hours} Jam`;
    const minutesString = `${minutes} Menit`;

    if (toText)
      return `${hours < 10 ? (hours != 0 ? hoursString : "") : hoursString}  ${
        hours > 0 && minutes != 0 ? minutesString : hours == 0 ? minutesString : ""
      }
       `;
    else return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  };

  const validateTimeFormat = (val) => {
    const timeFormat = /^([0-9]|[0-9][0-9]|[0-9][0-9]:|[0-9][0-9]:[0-5]|[0-9][0-9]:[0-5][0-9])$/;

    if (!timeFormat.test(val)) {
      return val.slice(0, -1);
    } else {
      return val;
    }
  };

  const formatSeparator = (value, format = true) => {
    if (!format || (!value && value != 0)) return value ?? "";
    value = value.toString();
    value = value.replace(/[^0-9.-]/g, "");
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const parseSeparator = (value, format = false) => {
    if (format) return parseFloat(value.replace(/,/g, ""));
    else return value.replace(/,/g, "");
  };

  return {
    getAsset,
    randomize,
    getAvatar,
    getQuarter,
    downloadCsv,
    toMinutes,
    toTime,
    validateTimeFormat,
    formatSeparator,
    parseSeparator,
  };
};

export default useUtility;
