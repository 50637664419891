<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 18 18" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.06565 1.39825C3.53449 0.929408 4.17037 0.666016 4.83342 0.666016H13.1667C13.8298 0.666016 14.4657 0.929408 14.9345 1.39825C15.4034 1.86709 15.6667 2.50297 15.6667 3.16602V15.666H16.5001C16.9603 15.666 17.3334 16.0391 17.3334 16.4993C17.3334 16.9596 16.9603 17.3327 16.5001 17.3327H1.50008C1.03984 17.3327 0.666748 16.9596 0.666748 16.4993C0.666748 16.0391 1.03984 15.666 1.50008 15.666H2.33341V3.16602C2.33341 2.50297 2.59681 1.86709 3.06565 1.39825ZM4.00008 15.666V3.16602C4.00008 2.945 4.08788 2.73304 4.24416 2.57676C4.40044 2.42048 4.6124 2.33268 4.83342 2.33268H13.1667C13.3878 2.33268 13.5997 2.42048 13.756 2.57676C13.9123 2.73304 14.0001 2.945 14.0001 3.16602V15.666H11.5001V12.3327C11.5001 11.8907 11.3245 11.4667 11.0119 11.1542C10.6994 10.8416 10.2754 10.666 9.83342 10.666H8.16675C7.72472 10.666 7.3008 10.8416 6.98824 11.1542C6.67568 11.4667 6.50008 11.8907 6.50008 12.3327V15.666H4.00008ZM8.16675 15.666H9.83342V12.3327H8.16675V15.666ZM5.66675 4.83268C5.66675 4.37245 6.03984 3.99935 6.50008 3.99935H7.33342C7.79365 3.99935 8.16675 4.37245 8.16675 4.83268C8.16675 5.29292 7.79365 5.66602 7.33342 5.66602H6.50008C6.03984 5.66602 5.66675 5.29292 5.66675 4.83268ZM9.83342 4.83268C9.83342 4.37245 10.2065 3.99935 10.6667 3.99935H11.5001C11.9603 3.99935 12.3334 4.37245 12.3334 4.83268C12.3334 5.29292 11.9603 5.66602 11.5001 5.66602H10.6667C10.2065 5.66602 9.83342 5.29292 9.83342 4.83268ZM5.66675 8.16602C5.66675 7.70578 6.03984 7.33268 6.50008 7.33268H7.33342C7.79365 7.33268 8.16675 7.70578 8.16675 8.16602C8.16675 8.62625 7.79365 8.99935 7.33342 8.99935H6.50008C6.03984 8.99935 5.66675 8.62625 5.66675 8.16602ZM9.83342 8.16602C9.83342 7.70578 10.2065 7.33268 10.6667 7.33268H11.5001C11.9603 7.33268 12.3334 7.70578 12.3334 8.16602C12.3334 8.62625 11.9603 8.99935 11.5001 8.99935H10.6667C10.2065 8.99935 9.83342 8.62625 9.83342 8.16602Z"
      fill="currentColor"
    />
  </svg>
</template>
