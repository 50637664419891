<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 18" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.83398 2.33329C2.61297 2.33329 2.40101 2.42109 2.24473 2.57737C2.08845 2.73365 2.00065 2.94561 2.00065 3.16663V14.8333C2.00065 15.0543 2.08845 15.2663 2.24473 15.4225C2.40101 15.5788 2.61297 15.6666 2.83398 15.6666H11.1673C11.3883 15.6666 11.6003 15.5788 11.7566 15.4225C11.9129 15.2663 12.0007 15.0543 12.0007 14.8333L12.0007 6.84514L7.4889 2.33338L2.83398 2.33329ZM1.06622 1.39886C1.53506 0.930018 2.17094 0.666626 2.83398 0.666626H7.48898C7.93093 0.66672 8.35493 0.842344 8.66741 1.15487M8.66741 1.15487L13.179 5.66645C13.179 5.66648 13.179 5.66642 13.179 5.66645C13.4915 5.97893 13.6672 6.40283 13.6673 6.84478V14.8333C13.6673 15.4963 13.4039 16.1322 12.9351 16.6011C12.4662 17.0699 11.8304 17.3333 11.1673 17.3333H2.83398C2.17094 17.3333 1.53506 17.0699 1.06622 16.6011C0.597376 16.1322 0.333984 15.4963 0.333984 14.8333V3.16663C0.333984 2.50358 0.597376 1.8677 1.06622 1.39886M9.50065 7.33329C9.96089 7.33329 10.334 7.70639 10.334 8.16663V13.1666C10.334 13.6269 9.96089 14 9.50065 14C9.04041 14 8.66732 13.6269 8.66732 13.1666V8.16663C8.66732 7.70639 9.04041 7.33329 9.50065 7.33329ZM7.00065 8.99996C7.46089 8.99996 7.83398 9.37306 7.83398 9.83329V13.1666C7.83398 13.6269 7.46089 14 7.00065 14C6.54041 14 6.16732 13.6269 6.16732 13.1666V9.83329C6.16732 9.37306 6.54041 8.99996 7.00065 8.99996ZM4.50065 10.6666C4.96089 10.6666 5.33398 11.0397 5.33398 11.5V13.1666C5.33398 13.6269 4.96089 14 4.50065 14C4.04041 14 3.66732 13.6269 3.66732 13.1666V11.5C3.66732 11.0397 4.04041 10.6666 4.50065 10.6666Z"
      fill="currentColor"
    />
  </svg>
</template>
