import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import calendar from "dayjs/plugin/calendar";
import "dayjs/locale/id";

const useDayJS = () => {
  dayjs.locale("id");
  dayjs.extend(quarterOfYear);
  dayjs.extend(calendar);

  dayjs().calendar(null, {
    sameDay: "asd",
  });

  return dayjs;
};

export default useDayJS;
