import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import "virtual:windi.css";
import "./styles/main.css";
import { useDayJS, useUtility } from "./composables";

const dayjs = useDayJS();
const { formatSeparator } = useUtility();

const app = createApp(App);

app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$formatSeparator = formatSeparator;

app.use(createPinia());
app.use(router);

app.mount("#app");
