import { useCookies } from "@vueuse/integrations/useCookies";
import { useJwt } from "@vueuse/integrations/useJwt";

const useSession = (type = "") => {
  const cookies = useCookies();

  if (type != "raw") {
    const { payload: session } = useJwt(cookies.get("session-lipmance"), { fallbackValue: {} });

    return session;
  } else {
    return cookies.get("session-lipmance");
  }
};

export default useSession;
