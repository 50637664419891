import { defineStore } from "pinia";
import { ref } from "vue";

export const useStateStore = defineStore("state", () => {
  const state = ref({});

  const setState = (payload, callback = () => {}) => {
    state.value = payload;
    callback();
  };

  const clearState = (callback = () => {}) => {
    state.value = {};
    callback();
  };

  return {
    state,
    setState,
    clearState,
  };
});
