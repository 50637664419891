<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 18 18" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.41077 0.910734C8.7362 0.585297 9.26384 0.585297 9.58928 0.910734L17.0893 8.41073C17.4147 8.73617 17.4147 9.26381 17.0893 9.58925C16.7638 9.91468 16.2362 9.91468 15.9108 9.58925L15.6667 9.34517V15.6667C15.6667 16.1087 15.4911 16.5326 15.1785 16.8452C14.866 17.1577 14.442 17.3333 14 17.3333H4.00002C3.55799 17.3333 3.13407 17.1577 2.82151 16.8452C2.50895 16.5326 2.33335 16.1087 2.33335 15.6667V9.34517L2.08928 9.58925C1.76384 9.91468 1.2362 9.91468 0.910765 9.58925C0.585328 9.26381 0.585328 8.73617 0.910765 8.41073L8.41077 0.910734ZM4.00002 7.6785V15.6667H6.50002V12.3333C6.50002 11.8913 6.67562 11.4674 6.98818 11.1548C7.30074 10.8423 7.72466 10.6667 8.16669 10.6667H9.83335C10.2754 10.6667 10.6993 10.8423 11.0119 11.1548C11.3244 11.4674 11.5 11.8913 11.5 12.3333V15.6667H14V7.6785L9.00002 2.6785L4.00002 7.6785ZM9.83335 15.6667V12.3333H8.16669V15.6667H9.83335Z"
      fill="currentColor"
    />
  </svg>
</template>
