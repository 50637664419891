<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.50002 2.33332C5.83698 2.33332 5.20109 2.59672 4.73225 3.06556C4.26341 3.5344 4.00002 4.17028 4.00002 4.83332C4.00002 5.49636 4.26341 6.13225 4.73225 6.60109C5.20109 7.06993 5.83698 7.33332 6.50002 7.33332C7.16306 7.33332 7.79895 7.06993 8.26779 6.60109C8.73663 6.13225 9.00002 5.49636 9.00002 4.83332C9.00002 4.17028 8.73663 3.5344 8.26779 3.06556C7.79895 2.59672 7.16306 2.33332 6.50002 2.33332ZM3.55374 1.88704C4.33514 1.10564 5.39495 0.666656 6.50002 0.666656C7.60509 0.666656 8.6649 1.10564 9.4463 1.88704C10.2277 2.66845 10.6667 3.72825 10.6667 4.83332C10.6667 5.93839 10.2277 6.9982 9.4463 7.7796C8.6649 8.561 7.60509 8.99999 6.50002 8.99999C5.39495 8.99999 4.33514 8.561 3.55374 7.7796C2.77234 6.9982 2.33335 5.93839 2.33335 4.83332C2.33335 3.72825 2.77234 2.66845 3.55374 1.88704ZM14 5.66666C14.4603 5.66666 14.8334 6.03975 14.8334 6.49999V8.16666H16.5C16.9603 8.16666 17.3334 8.53975 17.3334 8.99999C17.3334 9.46023 16.9603 9.83332 16.5 9.83332H14.8334V11.5C14.8334 11.9602 14.4603 12.3333 14 12.3333C13.5398 12.3333 13.1667 11.9602 13.1667 11.5V9.83332H11.5C11.0398 9.83332 10.6667 9.46023 10.6667 8.99999C10.6667 8.53975 11.0398 8.16666 11.5 8.16666H13.1667V6.49999C13.1667 6.03975 13.5398 5.66666 14 5.66666ZM6.50002 11.5C5.39495 11.5 4.33514 11.939 3.55374 12.7204C2.77234 13.5018 2.33335 14.5616 2.33335 15.6667H10.6667C10.6667 14.5616 10.2277 13.5018 9.4463 12.7204C8.6649 11.939 7.60509 11.5 6.50002 11.5ZM2.37523 11.5419C3.46919 10.4479 4.95292 9.83332 6.50002 9.83332C8.04712 9.83332 9.53085 10.4479 10.6248 11.5419C11.7188 12.6358 12.3334 14.1196 12.3334 15.6667V16.5C12.3334 16.9602 11.9603 17.3333 11.5 17.3333H1.50002C1.03978 17.3333 0.666687 16.9602 0.666687 16.5V15.6667C0.666687 14.1196 1.28127 12.6358 2.37523 11.5419Z"
      fill="currentColor"
    />
  </svg>
</template>
