<script setup>
import { useSession } from "@/composables";
import { Home } from "@icons/index";
import { computed } from "@vue/reactivity";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

const session = useSession();

const roleTypeID = computed(() => session.value.role.role_type.id);

const route = useRoute();
const breadcrumbs = computed(() => {
  return route.matched.filter((r) => {
    return r.meta?.hidden?.breadcrumbs != true;
  });
});

const submenus = ref([]);

watch(
  route,
  (to) => {
    submenus.value = to.matched[1].children
      .filter((r) => {
        return r.meta.hidden?.submenu != true;
      })
      .filter((r) => {
        if (r.meta.roles) {
          return r.meta.roles?.includes(roleTypeID.value);
        } else {
          return r;
        }
      });
  },
  { immediate: true },
);

// const notHiddenSubmenus = computed(() => {
//   return route.matched[1].children.filter((r) => r.meta.hidden?.submenu != true) ?? [];
// });

// const submenus = computed(() => {
//   return notHiddenSubmenus.value.filter((r) => r.meta.roles?.includes(roleTypeID.value));
// });
</script>

<template>
  <div>
    <el-breadcrumb class="flex items-center mb-6" separator="/">
      <el-breadcrumb-item v-for="(bc, i) in breadcrumbs">
        <router-link
          :to="{ name: bc.name }"
          :class="{ '!text-primary': i == breadcrumbs.length - 1 }"
        >
          <Home v-if="i == 0" />
          <span v-else class="font-semibold cursor-pointer">{{ bc.meta?.label }}</span>
        </router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div v-if="submenus.length" class="flex space-x-8">
      <template v-for="sm in submenus" :key="sm.name">
        <router-link :to="{ name: sm.name }" class="submenu-link">
          {{ sm.meta?.label }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<style>
.submenu-link {
  @apply font-semibold text-xl block pb-6 text-inactive border-b-4 border-transparent;
}

.submenu-link.router-link-active {
  @apply text-primary border-primary;
}
</style>
